import React, { useEffect, useState } from "react";
import api from "../../api/config";
import { ListAttachments, DownloadAttachment } from "../../api/Patient";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import EhrSearch from "../shared/EhrSearch";

const StyledTableContainer = styled(TableContainer)`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledTableCell = styled(TableCell)`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const PaginationContainer = styled.div`
  padding: 10px;
`;

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: "#3E526A !important",
    color: "#A5E0FF !important",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "14px",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      backgroundColor: "#3E526A !important",
      color: "#A5E0FF !important",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "14px",
    },
  },
  selectedRow: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
  },
});

const Attachments = ({ selectedPatient }) => {
  const [documentList, setDocumentList] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "fileName",
    direction: "asc",
  });
  const [selectedFileName, setSelectedFileName] = useState("");
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDocumentList = async (retryCount = 0) => {
      try {
        if (selectedPatient && selectedPatient.uuid) {
          const response = await api.get(
            `${ListAttachments}/${selectedPatient.uuid}`
          );
          console.log("DATA: ", response.data);
          if (response.data.files && response.data.files.length > 0) {
            setDocumentList(response.data.files);
            setError(false);
          } else {
            setDocumentList([]);
          }
        } else {
          console.warn("No selected patient or missing UUID");
          setDocumentList([]);
        }
      } catch (error) {
        console.error("Error fetching Document List:", error);
        if (retryCount < 3) {
          fetchDocumentList(retryCount + 1);
        } else {
          setError(true);
          setDocumentList([]);
        }
      }
    };

    if (selectedPatient) {
      fetchDocumentList();
    }
  }, [selectedPatient]);

  useEffect(() => {
    if (documentList) {
      let filtered = documentList.filter((fileName) =>
        fileName.toLowerCase().includes(searchTerm.toLowerCase())
      );

      filtered = filtered.sort((a, b) => {
        if (sortConfig.direction === "asc") {
          return a.localeCompare(b);
        } else {
          return b.localeCompare(a);
        }
      });

      setFilteredDocuments(filtered);
    } else {
      setFilteredDocuments([]);
    }
  }, [documentList, searchTerm, sortConfig]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = async (fileName) => {
    setSelectedFileName(fileName);
    setLoading(true);
    try {
      if (selectedPatient && selectedPatient.uuid && fileName) {
        const response = await api.get(
          `${DownloadAttachment}/${selectedPatient.uuid}/${fileName}`,
          { responseType: "blob" }
        );

        const fileBlob = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(fileBlob);
        setPdfPreviewUrl(fileURL);
      }
    } catch (error) {
      console.error("Error fetching Document XML:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <EhrSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {error || (documentList && documentList.length === 0) ? (
        <p>No Documents Found...</p>
      ) : !documentList ? (
        <p>Loading Documents...</p>
      ) : (
        <>
          <StyledTableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <StyledTableCell className={classes.headerCell}>
                    #
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "fileName"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("fileName")}
                    >
                      File Name
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDocuments
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((fileName, index) => (
                    <TableRow
                      key={index}
                      hover
                      onClick={() => handleRowClick(fileName)}
                      className={
                        fileName === selectedFileName ? classes.selectedRow : ""
                      }
                    >
                      <StyledTableCell style={{ padding: "10px" }}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell style={{ padding: "10px" }}>
                        {fileName}
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <PaginationContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredDocuments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </PaginationContainer>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress style={{ fill: "white" }} />
            </Box>
          ) : (
            pdfPreviewUrl && (
              <iframe
                src={pdfPreviewUrl}
                title={`PDF Preview - ${selectedFileName}`}
                style={{
                  width: "100%",
                  height: "90vh",
                  border: "none",
                }}
              />
            )
          )}
        </>
      )}
    </div>
  );
};

export default Attachments;
