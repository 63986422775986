import React, { useEffect, useState } from "react";
import api from "../../api/config";
import {
  GetPatientDocumentList,
  GetPatientDocumentXML,
} from "../../api/Patient";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Button,
  Modal,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import EhrSearch from "../shared/EhrSearch";
import html2pdf from "html2pdf.js";

const StyledTableContainer = styled(TableContainer)`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledTableCell = styled(TableCell)`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const PaginationContainer = styled.div`
  padding: 10px;
`;

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: "#3E526A !important",
    color: "#A5E0FF !important",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "14px",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      backgroundColor: "#3E526A !important",
      color: "#A5E0FF !important",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "14px",
    },
  },
});

const Documents = ({ selectedPatient }) => {
  const [documentList, setDocumentList] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "fileName",
    direction: "asc",
  });
  const [xmlContent, setXmlContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const classes = useStyles();
  const [error, setError] = useState(false);

  const handleCloseModal = () => setModalOpen(false);

  useEffect(() => {
    const fetchDocumentList = async (retryCount = 0) => {
      try {
        if (selectedPatient && selectedPatient.uuid) {
          const response = await api.get(
            `${GetPatientDocumentList}/${selectedPatient.uuid}`
          );
          if (response.data.length > 0) {
            setDocumentList(response.data);
            setError(false);
          } else {
            setDocumentList([]);
          }
        } else {
          console.warn("No selected patient or missing UUID");
          setDocumentList([]);
        }
      } catch (error) {
        console.error("Error fetching Document List:", error);
        if (retryCount < 3) {
          fetchDocumentList(retryCount + 1);
        } else {
          setError(true);
          setDocumentList([]);
        }
      }
    };

    if (selectedPatient) {
      fetchDocumentList();
    }
  }, [selectedPatient]);

  useEffect(() => {
    if (documentList) {
      let filtered = documentList.filter((fileName) =>
        fileName.toLowerCase().includes(searchTerm.toLowerCase())
      );

      filtered = filtered.sort((a, b) => {
        if (sortConfig.direction === "asc") {
          return a.localeCompare(b);
        } else {
          return b.localeCompare(a);
        }
      });

      setFilteredDocuments(filtered);
    } else {
      setFilteredDocuments([]);
    }
  }, [documentList, searchTerm, sortConfig]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = async (fileName) => {
    setSelectedFileName(fileName);
    setModalOpen(true);
    setLoading(true);
    try {
      if (selectedPatient && selectedPatient.uuid && fileName) {
        const response = await api.get(
          `${GetPatientDocumentXML}/${selectedPatient.uuid}/${fileName}`
        );
        setXmlContent(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching Document XML:", error);
      setLoading(false);
    }
  };

  const handlePrint = () => {
    const elementHTML = document.getElementById("xml-container");

    const pdfFileName = selectedFileName.replace(".xml", ".pdf");

    const opt = {
      margin: 1,
      filename: pdfFileName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(elementHTML).set(opt).save();
  };

  const handleViewerClick = (fileName) => (event) => {
    event.stopPropagation();
    let nexusToken = localStorage.getItem("token");
    let patientId = selectedPatient.uuid;

    window.open(
      `https://ccda.nexus-md.com?patient_id=${patientId}&document_name=${fileName}&nexus_token=${nexusToken}`,
      "_blank"
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <EhrSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {error || (documentList && documentList.length === 0) ? (
        <p>No Documents Found...</p>
      ) : !documentList ? (
        <p>Loading Documents...</p>
      ) : (
        <>
          <StyledTableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <StyledTableCell className={classes.headerCell}>
                    #
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "fileName"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("fileName")}
                    >
                      File Name
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.headerCell}
                  ></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDocuments
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((fileName, index) => (
                    <TableRow
                      key={index}
                      hover
                      onClick={() => handleRowClick(fileName)}
                    >
                      <StyledTableCell style={{ padding: "10px" }}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell style={{ padding: "10px" }}>
                        {fileName}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          onClick={handleViewerClick(fileName)}
                          variant="contained"
                          color="primary"
                        >
                          CCDA Viewer
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <PaginationContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredDocuments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              slotProps={{
                select: {
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: "#141b25",
                        color: "#FBFEFF",
                      },
                    },
                  },
                },
              }}
            />
          </PaginationContainer>

          <StyledModal open={modalOpen} onClose={handleCloseModal}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: "#3E526A",
                borderRadius: 2,
                maxHeight: "600px",
                overflowY: "auto",
                width: "70vw",
                position: "relative",
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 8, right: 8 }}
                onClick={handleCloseModal}
              >
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>

              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <>
                  <Button
                    onClick={handlePrint}
                    variant="contained"
                    color="primary"
                    sx={{ marginBottom: 2 }}
                  >
                    Print
                  </Button>
                  {xmlContent ? (
                    <div
                      id="xml-container"
                      style={{ color: "black" }}
                      dangerouslySetInnerHTML={{ __html: xmlContent }}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </>
              )}
            </Box>
          </StyledModal>
        </>
      )}
    </div>
  );
};

export default Documents;
