import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Collapse,
  IconButton,
  TablePagination,
} from "@mui/material";
import { TimelineOutlined } from "@mui/icons-material";
import styled from "styled-components";
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  FormatListBulleted,
} from "@mui/icons-material";
import { GetPatientVitalSigns } from "../../api/Patient";
import api from "../../api/config";
import Timeline from "./Timeline";
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding: 0 16px;
`;

const StyledButton = styled(IconButton)`
  background-color: ${(props) => (props.active ? "#7efbfd" : "#3b4252")};
  color: ${(props) => (props.active ? "#3b4252" : "white")};
  font-size: 14px;
  font-weight: bold;
  padding: 8px;
  border: 1px solid #7efbfd;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 150px;
  text-align: center;

  border-top-left-radius: ${(props) => (props.text === "left" ? "50px" : "0")};
  border-bottom-left-radius: ${(props) =>
    props.text === "left" ? "50px" : "0"};
  border-top-right-radius: ${(props) =>
    props.text === "right" ? "50px" : "0"};
  border-bottom-right-radius: ${(props) =>
    props.text === "right" ? "50px" : "0"};

  &:hover {
    background-color: ${(props) => (props.active ? "#66d9c9" : "#444e6b")};
  }
`;

const InfoTable = styled.div`
  background-color: #3b4252;
  padding: 16px;
  border-radius: 8px;
  height: 70vh;
  overflow-y: auto;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #283a52;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #28929c;
    border: 1px solid #3e526a;
    border-radius: 100px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }
`;

const PatientEHR = ({ patient }) => {
  const [vitalSigns, setVitalSigns] = useState([]);
  const [vitalSignsLoading, setVitalSignsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedButton, setSelectedButton] = useState("Table");

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const toggleRowExpansion = (studyId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(studyId)
        ? prevExpandedRows.filter((id) => id !== studyId)
        : [...prevExpandedRows, studyId]
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchPatientVitalSigns = async () => {
      try {
        console.log("Fetching patient vital signs", patient.uuid);
        const response = await api.get(
          `${GetPatientVitalSigns}/${patient.uuid}?simplify=true`
        );
        setVitalSigns(response.data);
      } catch (error) {
        console.error("Failed to fetch patient vital signs", error);
      } finally {
        setVitalSignsLoading(false);
      }
    };
    fetchPatientVitalSigns();
  }, [patient.uuid]);

  const sortedVitalSigns = [...vitalSigns].sort((a, b) => {
    const dateA = new Date(a.effectiveDateTime);
    const dateB = new Date(b.effectiveDateTime);
    return dateB - dateA;
  });

  const paginatedVitalSigns = sortedVitalSigns.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div style={{ marginTop: "16px" }}>
      <ButtonContainer>
        <StyledButton
          text="left"
          active={selectedButton === "Timeline"}
          onClick={() => handleButtonClick("Timeline")}
        >
          <TimelineOutlined style={{ marginRight: "8px" }} />
          Timeline
        </StyledButton>
        <StyledButton
          text="right"
          active={selectedButton === "Table"}
          onClick={() => handleButtonClick("Table")}
        >
          <FormatListBulleted style={{ marginRight: "8px" }} />
          Table
        </StyledButton>
      </ButtonContainer>
      <InfoTable>
        {selectedButton === "Timeline" ? (
          <Timeline selectedPatient={patient} vitalSigns={sortedVitalSigns} />
        ) : (
          <div>
            <Table stickyHeader>
              {vitalSignsLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: "center" }}>
                      <CircularProgress style={{ color: "#FBFEFF" }} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {paginatedVitalSigns.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} style={{ textAlign: "center" }}>
                        No EHR data for the patient
                      </TableCell>
                    </TableRow>
                  ) : (
                    paginatedVitalSigns.map((vital, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell style={{ padding: "2px" }}>
                            <IconButton
                              size="small"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              {expandedRows.includes(index) ? (
                                <KeyboardArrowDown
                                  style={{ filter: "invert(1)" }}
                                />
                              ) : (
                                <KeyboardArrowRight
                                  style={{ filter: "invert(1)" }}
                                />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {new Date(vital.effectiveDateTime).toLocaleString()}
                          </TableCell>
                          <TableCell>{vital.status}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse
                              in={expandedRows.includes(index)}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Table size="small">
                                <TableBody>
                                  {vital.components.map(
                                    (component, compIndex) => (
                                      <TableRow key={compIndex}>
                                        <TableCell>{component.code}</TableCell>
                                        <TableCell>{`${component.value} ${component.unit}`}</TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sortedVitalSigns.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </InfoTable>
    </div>
  );
};

export default PatientEHR;
