import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  CircularProgress,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  Legend,
} from "recharts";

const Timeline = ({ selectedPatient, vitalSigns }) => {
  const [selectedVitalSign, setSelectedVitalSign] = useState(null);
  const [loading, setLoading] = useState(false);

  const groupedVitalSigns = (vitalSigns || []).reduce((acc, vital) => {
    vital.components.forEach((component) => {
      if (
        component.code !== "Systolic blood pressure" &&
        component.code !== "Diastolic blood pressure"
      ) {
        const key = `${component.code}-${component.unit}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push({
          value: component.value,
          unit: component.unit,
          effectiveDateTime: vital.effectiveDateTime,
        });
      }
    });
    return acc;
  }, {});

  const dropdownOptions = [
    ...Object.keys(groupedVitalSigns).map((key) => {
      const [code, unit] = key.split("-");
      return { code, unit, key };
    }),
    { code: "Blood Pressure", unit: "mm[Hg]", key: "bloodPressure" },
  ];

  const handleSelectChange = (event) => {
    setSelectedVitalSign(event.target.value);
  };

  const chartData =
    selectedVitalSign && selectedVitalSign !== "bloodPressure"
      ? groupedVitalSigns[selectedVitalSign].map((entry) => ({
          date: new Date(entry.effectiveDateTime).toLocaleString(),
          value: entry.value,
          unit: entry.unit,
        }))
      : [];

  const bloodPressureData = vitalSigns
    ? vitalSigns.reduce((acc, vital) => {
        const systolicComponent = vital.components.find(
          (comp) => comp.code === "Systolic blood pressure"
        );
        const diastolicComponent = vital.components.find(
          (comp) => comp.code === "Diastolic blood pressure"
        );

        if (systolicComponent || diastolicComponent) {
          const date = new Date(vital.effectiveDateTime).toLocaleString();
          acc.push({
            date,
            systolic: systolicComponent ? systolicComponent.value : null,
            diastolic: diastolicComponent ? diastolicComponent.value : null,
          });
        }
        return acc;
      }, [])
    : [];

  useEffect(() => {
    if (!vitalSigns || vitalSigns.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [vitalSigns]);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <FormControl style={{ width: "300px" }}>
            <InputLabel style={{ color: "white" }}>Vital Signs</InputLabel>
            <Select
              value={selectedVitalSign || ""}
              onChange={handleSelectChange}
              label="Vital Signs"
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "#141b25",
                    color: "#FBFEFF",
                  },
                },
              }}
              sx={{
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ffffff",
                  borderRadius: "12px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ffffff",
                  borderRadius: "12px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ffffff",
                  borderRadius: "12px",
                },
                ".MuiSvgIcon-root": {
                  fill: "white !important",
                },
              }}
            >
              {dropdownOptions.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.code} - {option.unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedVitalSign === "bloodPressure" &&
            bloodPressureData.length > 0 && (
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ marginTop: "25px" }}
              >
                <ScatterChart>
                  <CartesianGrid stroke="#d3d3d3" vertical={false} />
                  <XAxis
                    type="category"
                    dataKey="date"
                    tick={{ fill: "white" }}
                    name="Date"
                    tickFormatter={(tick) =>
                      new Date(tick).toLocaleDateString()
                    }
                  />
                  <YAxis type="number" tick={{ fill: "white" }} name="Value" />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Legend verticalAlign="top" height={36} />
                  <Line
                    type="monotone"
                    dataKey="systolic"
                    stroke="#ff7300"
                    strokeWidth={2}
                    dot={true}
                    name="Systolic"
                  />
                  <Line
                    type="monotone"
                    dataKey="diastolic"
                    stroke="#387908"
                    strokeWidth={2}
                    dot={true}
                    name="Diastolic"
                  />
                  <Scatter
                    name="Systolic"
                    data={bloodPressureData}
                    fill="#ff7300"
                    line
                    dataKey="systolic"
                  />
                  <Scatter
                    name="Diastolic"
                    data={bloodPressureData}
                    fill="#387908"
                    line
                    dataKey="diastolic"
                  />
                </ScatterChart>
              </ResponsiveContainer>
            )}

          {selectedVitalSign !== "bloodPressure" &&
            selectedVitalSign &&
            chartData.length > 0 && (
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ marginTop: "25px" }}
              >
                <ScatterChart>
                  <CartesianGrid stroke="#d3d3d3" vertical={false} />
                  <XAxis
                    type="category"
                    dataKey="date"
                    tick={{ fill: "white" }}
                    name="Date"
                    tickFormatter={(tick) =>
                      new Date(tick).toLocaleDateString()
                    }
                  />
                  <YAxis
                    type="number"
                    dataKey="value"
                    tick={{ fill: "white" }}
                    name="Value"
                  />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Line
                    type="monotone"
                    data={chartData}
                    dataKey="value"
                    stroke="#ffffff"
                    strokeWidth={2}
                    dot={true}
                  />
                  <Scatter
                    name="Vital Sign"
                    data={chartData}
                    fill="#8884d8"
                    line
                  />
                </ScatterChart>
              </ResponsiveContainer>
            )}

          {selectedVitalSign &&
            chartData.length === 0 &&
            bloodPressureData.length === 0 && (
              <p>No data available for selected vital sign.</p>
            )}
        </div>
      )}
    </div>
  );
};

export default Timeline;
