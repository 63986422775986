import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tabs, Tab } from "@mui/material";
import Radiology from "../patientEhr/Radiology";
import Procedures from "../patientEhr/Procedures";
import Encounters from "../patientEhr/Encounters";
import Imaging from "../patientEhr/Imaging";
import Documents from "../patientEhr/Documents";
import Diagnostic from "../patientEhr/Diagnostic";
import Attachments from "../patientEhr/Attachments";
import { Accessibility, LocalHospital } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import PatientEHR from "./PatientEHR";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding: 0 16px;
`;

const StyledButton = styled(IconButton)`
  background-color: ${(props) => (props.active ? "#7efbfd" : "#3b4252")};
  color: ${(props) => (props.active ? "#3b4252" : "white")};
  font-size: 14px;
  font-weight: bold;
  padding: 8px;
  border: 1px solid #7efbfd;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 150px;
  text-align: center;

  border-top-left-radius: ${(props) => (props.text === "left" ? "50px" : "0")};
  border-bottom-left-radius: ${(props) =>
    props.text === "left" ? "50px" : "0"};
  border-top-right-radius: ${(props) =>
    props.text === "right" ? "50px" : "0"};
  border-bottom-right-radius: ${(props) =>
    props.text === "right" ? "50px" : "0"};

  &:hover {
    background-color: ${(props) => (props.active ? "#66d9c9" : "#444e6b")};
  }
`;

const Container = styled.div`
  background-color: #3b4252;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px #00000040;
  height: 70vh;
  overflow-y: auto;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #283a52;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #28929c;
    border: 1px solid #3e526a;
    border-radius: 100px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }
`;

const MainContent = styled.div`
  border-radius: 8px 8px 0px 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const EhrContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #7efbfd;
  margin-bottom: 16px;
  & .MuiTabs-indicator {
    background-color: #7efbfd;
    height: 4px;
  }

  & .MuiTab-root {
    color: white;
    font-size: 16px;
    text-transform: capitalize;
  }

  & .Mui-selected {
    font-weight: bold;
    color: #7efbfd !important;
  }
`;

const StyledTab = styled(Tab)`
  color: #fbfeff;

  &.Mui-selected {
    font-weight: bold;
    color: #7efbfd;
  }
`;

function PatientDetailedEHR({ patient, origin, backgroundColor }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedButton, setSelectedButton] = useState("Patient 360");

  useEffect(() => {
    patient.patient_uuid = patient.uuid;
  }, [patient]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const renderContent = () => {
    if (selectedButton === "Patient 360") {
      return (
        <div
          style={{
            padding: origin !== "expandedEhr" ? "16px" : "0px",
            flexGrow: 1,
            display: "inherit",
            overflow: "auto",
          }}
        >
          <MainContent $origin={origin} $backgroundColor={backgroundColor}>
            <StyledTabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="patient-eh-menu"
            >
              <StyledTab label={`Radiology (Beta)`} />
              <StyledTab label={`Procedures`} />
              <StyledTab label={`Labs/Imaging`} />
              <StyledTab label={`Encounters`} />
              <StyledTab label={`Documents`} />
              <StyledTab label={`Diagnostic`} />
              <StyledTab label={`Attachments`} />
            </StyledTabs>
            <EhrContainer>{renderTabContent()}</EhrContainer>
          </MainContent>
        </div>
      );
    }
    return <PatientEHR patient={patient} />;
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <Radiology selectedPatient={patient} />;
      case 1:
        return <Procedures selectedPatient={patient} />;
      case 2:
        return <Imaging selectedPatient={patient} />;
      case 3:
        return <Encounters selectedPatient={patient} />;
      case 4:
        return <Documents selectedPatient={patient} />;
      case 5:
        return <Diagnostic selectedPatient={patient} />;
      case 6:
        return <Attachments selectedPatient={patient} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <ButtonContainer>
        <StyledButton
          text="left"
          active={selectedButton === "Patient 360"}
          onClick={() => handleButtonClick("Patient 360")}
        >
          <Accessibility style={{ marginRight: "8px" }} />
          Patient 360
        </StyledButton>
        <StyledButton
          text="right"
          active={selectedButton === "Vital Signs"}
          onClick={() => handleButtonClick("Vital Signs")}
        >
          <LocalHospital style={{ marginRight: "8px" }} />
          Vital Signs
        </StyledButton>
      </ButtonContainer>
      <Container origin={origin}>{renderContent()}</Container>
    </div>
  );
}

export default PatientDetailedEHR;
